import { CompetitionFragmentFragment } from "~graphql/sdk";
import { formatDate } from "~lib";

export function competitionClosesText(
  competition: CompetitionFragmentFragment
) {
  if (!competition || typeof competition.endDate !== "string") {
    return "";
  }
  const dateFormat = "dd MMMM yyyy 'at' h:mm a";

  const endDateFormatted = formatDate(competition.endDate, dateFormat) ?? "";

  return `Competition closes ${endDateFormatted}`;
}
