import { Box, Flex, Text, UnderlineButton } from "flicket-ui";
import { competitionClosesText } from "~features/events/utils/competition";
import { CompetitionFragmentFragment } from "~graphql/sdk";

interface CompetitionCardFooterProps {
  competition: CompetitionFragmentFragment;
}

export default function CompetitionCardFooter(
  props: CompetitionCardFooterProps
) {
  return (
    <Flex flexDirection={"column"} style={{ gap: "12px" }}>
      <Text
        color="N700"
        fontWeight={"regular"}
        fontSize={2}
        textAlign={"center"}
      >
        {competitionClosesText(props.competition)}
      </Text>
      <Box textAlign={"center"}>
        <UnderlineButton
          href="#competition-details"
          color="N800"
          fontSize={3}
          fontWeight={"regular"}
        >
          More info
        </UnderlineButton>
      </Box>
    </Flex>
  );
}
