import { Flex, Text, Link } from "flicket-ui";
import { Icon } from "~components";
import React from "react";

interface BackLinkProps {
  backToLinkText?: string;
  backToLink?: string;
  isSinglePage?: boolean;
  tourId?: string;
}

export default function BackLink({
  backToLinkText = "Back to Events",
  backToLink = "/",
  tourId,
  isSinglePage,
}: BackLinkProps) {
  return (
    <Link to={tourId ? `/tours/${tourId}` : backToLink}>
      <Flex alignItems="center" opacity="inherit">
        <Icon icon="chevron-left" fontSize={5} color="N500" />
        {isSinglePage ? (
          <Text
            as={"h2"}
            color="N500"
            fontWeight="extraBold"
            fontSize={3}
            lineHeight="medium"
            ml="3/4"
          >
            Back to ticket selection
          </Text>
        ) : (
          <Text
            color="N500"
            fontWeight="extraBold"
            fontSize={3}
            lineHeight="medium"
            ml="3/4"
          >
            {backToLinkText}
          </Text>
        )}
      </Flex>
    </Link>
  );
}
