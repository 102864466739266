import { Flex, SecondaryButton, TextInput } from "flicket-ui";
import { EventQuery } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import useGoToRelease from "../hooks/useGoToRelease";
import useShowReleaseCodes from "../hooks/useShowReleaseCodes";

export default function ReleaseCodes(props: { event: EventQuery["event"] }) {
  const { event } = props;
  const { id, enableReleaseCodes } = event;
  const { organization } = useOrganization();
  const goToRelease = useGoToRelease(id);
  const showReleaseCodes = useShowReleaseCodes(enableReleaseCodes);

  if (!showReleaseCodes) {
    return null;
  }

  return (
    <>
      <Flex justifyContent="center">
        <TextInput
          name="reservationInput"
          id="reservationInput"
          width={"100%"}
          placeholder={
            organization?.id === "58106ce0-e1cd-47cb-9c59-0d0192ca6df8"
              ? "FOR THE LOVE OF THE CLUB CODE"
              : organization?.id === "45339f71-c0bb-41a9-b642-7d30f1321a84"
              ? "Club/School/Sponsor Code"
              : "Enter access code"
          }
        />
      </Flex>

      <Flex mt={2} justifyContent="center">
        <SecondaryButton
          fontSize={3}
          boxShadow="button"
          onClick={() => void goToRelease()}
          width={"100%"}
        >
          {organization?.id === "58106ce0-e1cd-47cb-9c59-0d0192ca6df8"
            ? "FOR THE LOVE OF THE CLUB"
            : organization?.id === "45339f71-c0bb-41a9-b642-7d30f1321a84"
            ? "Club/School/Sponsor Code"
            : "Unlock Allocated Tickets"}
        </SecondaryButton>
      </Flex>
    </>
  );
}
