import { useRouter } from "next/router";
import { Release } from "~graphql/fetchers";
import { EventQuery } from "~graphql/sdk";
import {
  CompetitionFragmentFragment,
  CompetitionType,
} from "~graphql/typed-document-nodes";
import {
  usePreviewCompetition,
  useWillShowPreviewCompetition,
} from "~features/events/hooks/usePreviewCompetition";

// The page can be in one of these states. Note: This is not the same as the state of the event since the event can have unlisted releases which are on sale.

type PageState =
  | "info"
  | "registration"
  | "registration-competition"
  | "onsale"
  | "onsale-competition";

interface EventPageContext {
  state: PageState;
  event: EventQuery["event"];
}

/* ------------------------- Context for each state ------------------------- */
export interface InfoContext extends EventPageContext {
  state: "info";
}

export interface RegistrationContext extends EventPageContext {
  state: "registration";
}

export interface RegistrationCompetitionContext extends EventPageContext {
  state: "registration-competition";
  competition: CompetitionFragmentFragment;
}

export interface OnsaleContext extends EventPageContext {
  state: "onsale";
}

export interface OnsaleCompetitionContext extends EventPageContext {
  state: "onsale-competition";
  competition: CompetitionFragmentFragment;
}

export type PageContext =
  | InfoContext
  | RegistrationContext
  | RegistrationCompetitionContext
  | OnsaleContext
  | OnsaleCompetitionContext;

function competitionWithSortedPrizes(
  competition: CompetitionFragmentFragment
): CompetitionFragmentFragment {
  return {
    ...competition,
    prizes: competition.prizes.sort((a, b) => a.prizeOrder - b.prizeOrder),
  };
}

/* ------------------------ Logic to determine state ------------------------ */
// Note: A lot of this logic can be moved to BE. We can have a single query that returns the state and the data for that state.
// That way we don't need to fetch all competitions.
const useDetailsPageContext = (
  event: EventQuery["event"],
  release: Release | undefined, // Release is undefined when there its info or registration only
  competitions: CompetitionFragmentFragment[] = []
): PageContext => {
  const router = useRouter();
  const { competition: previewCompetition } = usePreviewCompetition();
  const showPreviewCompetition = useWillShowPreviewCompetition();
  const isUnlistedRelease = router.query.slug ? true : false;

  // On sale
  const areTicketsAvailableForPurchase =
    (release?.isActive && isUnlistedRelease) || event?.hasPublicRelease;
  const onsaleCompetition = competitions.find(
    (c) => c.competitionType === CompetitionType.Onsale
  );
  const isOnsaleCompetitionAvailable = !!onsaleCompetition;

  // Registration
  const isRegistrationAvailable = event.isWaitlistAvailable;
  const registrationCompetition = competitions.find(
    (c) => c.competitionType === CompetitionType.Registration
  );
  const isRegistrationCompetitionAvailable = !!registrationCompetition;
  if (showPreviewCompetition && previewCompetition) {
    return {
      state:
        previewCompetition.competitionType === CompetitionType.Registration
          ? "registration-competition"
          : "onsale-competition",
      event,
      competition: competitionWithSortedPrizes(previewCompetition),
    };
  } else if (areTicketsAvailableForPurchase && !isOnsaleCompetitionAvailable) {
    return {
      state: "onsale",
      event,
    };
  } else if (areTicketsAvailableForPurchase && isOnsaleCompetitionAvailable) {
    return {
      state: "onsale-competition",
      event,
      competition: competitionWithSortedPrizes(onsaleCompetition),
    };
  } else if (isRegistrationAvailable && !isRegistrationCompetitionAvailable) {
    return {
      state: "registration",
      event,
    };
  } else if (isRegistrationAvailable && isRegistrationCompetitionAvailable) {
    return {
      state: "registration-competition",
      event,
      competition: competitionWithSortedPrizes(registrationCompetition),
    };
  }

  return {
    state: "info",
    event,
  };
};

export default useDetailsPageContext;

/* ----------------------------- Type predicates ---------------------------- */
export function isInfoContext(context: PageContext): context is InfoContext {
  return context.state === "info";
}

export function isRegistrationContext(
  context: PageContext
): context is RegistrationContext {
  return context.state === "registration";
}

export function isRegistrationCompetitionContext(
  context: PageContext
): context is RegistrationCompetitionContext {
  return context.state === "registration-competition";
}

export function isOnsaleContext(
  context: PageContext
): context is OnsaleContext {
  return context.state === "onsale";
}

export function isOnsaleCompetitionContext(
  context: PageContext
): context is OnsaleCompetitionContext {
  return context.state === "onsale-competition";
}
