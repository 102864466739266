import useGetTickets from "../hooks/useGetTickets";
import { PrimaryButton, Text } from "flicket-ui";
import {
  PageContext,
  isOnsaleCompetitionContext,
  isOnsaleContext,
  isRegistrationCompetitionContext,
  isRegistrationContext,
} from "../hooks/useDetailsPageContext";

export default function MainCTA(props: { pageContext: PageContext }) {
  const { pageContext } = props;
  const { navigate, url } = useGetTickets(pageContext.event.id);

  if (isOnsaleContext(pageContext) || isOnsaleCompetitionContext(pageContext)) {
    return (
      <PrimaryButton width="100%" to={url} onClick={navigate}>
        <Text fontSize={3}>Get tickets</Text>
      </PrimaryButton>
    );
  }

  if (
    isRegistrationContext(pageContext) ||
    isRegistrationCompetitionContext(pageContext)
  ) {
    return (
      <PrimaryButton
        width="100%"
        to={`/events/${pageContext.event.id}/waitlist`}
      >
        <Text fontSize={3}>Register</Text>
      </PrimaryButton>
    );
  }

  return null;
}
