/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { pick } from "@styled-system/props";
import {
  Box,
  Flex,
  Link,
  SystemProps,
  Text,
  UnderlineButton,
} from "flicket-ui";
import { useState } from "react";
import { ContentModal, Icon, SlateRenderer } from "~components";
import Image from "~components/common/Image";
import { EventQuery } from "~graphql/sdk";
import useDescriptionText from "~hooks/useDescriptionText";
import { formatDate } from "~lib";

interface EventInformationProps {
  event: EventQuery["event"];
}

export function EventSummary(props: EventInformationProps) {
  const { event } = props;
  const { endDate, startDate, accessibility, venue } = event;

  const [isAccessibilityModalOpen, setAccessibilityModalOpen] = useState(false);

  return (
    <>
      <Flex
        flexDirection={"column"}
        justifyContent="space-between"
        style={{ gap: "16px" }}
      >
        <Flex>
          <Icon icon="calendar-blank" fontSize={6} color="N800" />
          <Text color="N700" fontWeight="regular" fontSize={4} ml={1}>
            {endDate &&
            formatDate(startDate, "dd") !== formatDate(endDate, "dd")
              ? `${formatDate(startDate, "dd LLL")} - ${formatDate(
                  endDate,
                  "dd LLL yyyy"
                )}`
              : formatDate(startDate, "EEEE d MMMM, p")}
          </Text>
        </Flex>
        <Link
          display="flex"
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${venue?.location?.lat},${venue?.location?.lng}`}
        >
          <Icon icon="map-pin" fontSize={6} color="N800" />
          <Text color="N700" fontWeight="regular" fontSize={4} ml={1}>
            {`${venue?.name}, ${
              venue?.address?.city ? venue?.address?.city : null
            }`}
          </Text>
        </Link>
        {accessibility && (
          <Flex>
            <Icon icon="person-arms-spread" fontSize={6} color="N800" />
            <UnderlineButton onClick={() => setAccessibilityModalOpen(true)}>
              <Text color="N700" fontWeight="regular" fontSize={4} ml={1}>
                Accessibility Info
              </Text>
            </UnderlineButton>
          </Flex>
        )}
      </Flex>
      <ContentModal
        isOpen={isAccessibilityModalOpen}
        setIsOpen={() => setAccessibilityModalOpen(false)}
        title="Accessibility"
        richText={event?.accessibility}
      />
    </>
  );
}

export function EventDescription(props: EventInformationProps) {
  const { descriptionImage, description } = props.event;

  const descriptionText = useDescriptionText(description);
  return (
    <Box mt={5}>
      <SlateRenderer value={description} mb={3} />
      {descriptionImage && (
        <Image image={descriptionImage} alt={descriptionText} />
      )}
    </Box>
  );
}

export function TermsAndConditions(props: EventInformationProps) {
  const { termsAndConditions, id } = props.event;
  if (!termsAndConditions) return null;

  return (
    <UnderlineButton to={`/events/${id}/terms-and-conditions`} mt={5}>
      <Text color="N700" fontWeight="regular" fontSize={4}>
        Terms &amp; Conditions
      </Text>
    </UnderlineButton>
  );
}

// Event information - does not include the the summary
export default function EventInformation(
  props: EventInformationProps & SystemProps
) {
  return (
    <Box {...pick(props)}>
      <EventDescription {...props} />
      <TermsAndConditions {...props} />
    </Box>
  );
}
